import React,{useEffect,useState} from 'react';
import { HStack, Box, Image, Text, Button } from '@chakra-ui/react';
import LeftNav from './settings/LeftNav';
import Stripe from './settings/Stripe';
import Email from './settings/Email';
import NotificationTemplate from './settings/NotificationTemplate';
import StoryLanguage from './settings/StoryLanguage';
import AuthorType from './settings/AuthorType';
import Industry from './settings/Industry';




import User from './settings/User';
import Header from '../components/Header';

function Settings(){
	 const [ getPage, setGetPage ] = useState(false);


	
	 useEffect(() => {
        loadPage('stripe')
       
       
        
    }, []);

	const loadPage = (page_id) => {

		if(page_id == 'stripe'){
			setGetPage(<Stripe />)
		}

		if(page_id == 'email'){
			setGetPage(<Email />)
		}
		if(page_id == 'notificationtemplate'){
			setGetPage(<NotificationTemplate />)
		}
		if(page_id == 'storylanguage'){
			setGetPage(<StoryLanguage />)
		}
		if(page_id == 'authortype'){
			setGetPage(<AuthorType />)
		}
		if(page_id == 'industry'){
			setGetPage(<Industry />)
		}

		if(page_id == 'user'){
			setGetPage(<User />)
		}
	} 

	return(
		<>
		<Header />

		<div className="setting_page">
			<Box>
				<Text fontSize="32px" fontWeight="300" fontFamily="'Montserrat', sans-serif" color="#000" my="20px">Settings</Text>
				<HStack alignItems="top">
					<Box w="22%">
						<LeftNav loadPage={loadPage} />
					</Box>

					<Box w="78%">
						{getPage}
					</Box>
				</HStack>
			</Box>
		</div>				
		</>
	);
}

export default Settings;