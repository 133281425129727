import React,{useEffect,useState} from 'react';
import * as Yup from 'yup';
import {
  Box,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Badge,
  Image,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Link,
} from '@chakra-ui/react'
import "../../index.css";
import {InputText,Form,SelectField} from '../../components/form';
import { fetchWrapper } from '../../helpers';
import { toast } from "react-toastify";


function Stripe(){

  const [listData,setListData] = useState({})

  const [formData, setFormData] = useState({});
  const backendUrl = process.env.REACT_APP_API_URL
   useEffect(() => {
        loadListData()
       
       
        
    }, []);


   const loadListData = async () => {
      const url = `${backendUrl}/settings/list`;
      const data  = await fetchWrapper.get(url)
      

      setFormData(
              {
                  stripe_prod_public_key: findData('stripe_prod_public_key',data.data),
                  stripe_prod_private_key:findData('stripe_prod_private_key',data.data),
                  stripe_sandbox_public_key:findData('stripe_sandbox_public_key',data.data),
                  stripe_sandbox_private_key:findData('stripe_sandbox_private_key',data.data),
                  stripe_mode:findData('stripe_mode',data.data),
               
                }
        )
  }

  const findData = (key,dataValue) => {
      
      const data =  dataValue?.filter(rs => rs.key == key);
      
      if(data){
          return data[0].value;
      }
      return '';
  }

  


  const FormSchema = Yup.object().shape({
     stripe_prod_public_key: Yup.string().required('Required'),
     stripe_prod_private_key: Yup.string().required('Required'),
     stripe_sandbox_public_key: Yup.string().required('Required'),
     stripe_sandbox_private_key: Yup.string().required('Required'),
     stripe_mode: Yup.string().required('Required'),
     
  });


  const onSubmit = async (values, { setSubmitting, resetForm, setStatus }) => {

       let  data = values
      
         
       const url = `${process.env.REACT_APP_API_URL}/settings/create`;
       await fetchWrapper.post(url,data)
       toast(`Record has been saved `, {
          type: "info"
        });
       
  }


	return(
		<>

    
			<Box px="20px" py="10px" backgroundColor="#fff" borderWidth="1px" borderStyle="solid" borderColor="#ccc" borderRadius="10px">
        <Text fontSize="18px" fontWeight="600" fontFamily="'Montserrat', sans-serif" mb="20px" color="#000">Stripe</Text>

        <Form
        enableReinitialize
                  validationSchema={FormSchema}
                  initialValues={formData}
                  onSubmit={onSubmit}
        >
          <HStack alignItems="top">
            <Box width="44%">
              <Text fontSize="12px" fontWeight="600" fontFamily="'Montserrat', sans-serif" mb="20px" color="#000">Production Keys</Text>

              <InputText name="stripe_prod_public_key" title="Public Key" />

              <InputText name="stripe_prod_private_key" title="Private Key" />

              <HStack>
                <Box width="60%">
                  <SelectField list={{rows:[{name:"Production",id:'production'},
                    {name:"Send Box",id:'sendbox'}]}} name="stripe_mode" title="Mode" />
                </Box>
              </HStack>

              <Button my="20px" h="unset" py="13px" px="40px" color="#fff" bg="#0A83FF" fontSize="14px" fontWeight="400" fontFamily="'Montserrat', sans-serif"
                mb={4}
                
                type='submit'
              >
               Save
              </Button>
            </Box>

            <Box width="12%"></Box>

            <Box width="44%">
              <Text fontSize="12px" fontWeight="600" fontFamily="'Montserrat', sans-serif" mb="20px" color="#000">Sandbox Keys</Text>

              <InputText name="stripe_sandbox_public_key" title="Public Key" />

              <InputText name="stripe_sandbox_private_key" title="Private Key" />
            </Box>
          </HStack>
        </Form>
      </Box>
		</>
	)
}

export default Stripe;